import React from "react"
import "src/styles/pages/movement.scss"
import { Grid } from "@material-ui/core"

export default function TemporaryMovementPage() {
  return (
    <div className="movement-page-root">
      <h3>Mindance Bewegt</h3>
      <p>
        Dies ist eine exklusive Preview zu Mindance Bewegt. Mit kurzen
        Bewegungseinheiten kannst du deinen Rücken, deine Schultern, Arme und
        Hände dehnen und lockern, um so dein Wohlbefinden zu verbessern und
        deine Energiedepots aufzuladen.
      </p>
      <Grid item xs={12} sm={6} md={4}>
        <div className={"cardContainer"}>
          <h4>Körper auflockern</h4>
          <video
            width="100%"
            controls
            poster="/assets/img/movement/VideoThumbnail.png"
          >
            <source
              src="https://d3j42soofhp7qt.cloudfront.net/video/GKB newsletter.mp4"
              type="video/mp4"
            />
          </video>
          <p>Dehne deinen Körper und schöpfe Energie.</p>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <div className={"cardContainer"}>
          <h4>Preview: Stress abbauen</h4>
          <img src="/assets/img/movement/movement_video_screenshot3.png" />
          <p>Finde Entspannung und lass den Stress hinter dir.</p>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <div className={"cardContainer"}>
          <h4>Preview: Gelassenheit spüren</h4>
          <img src="/assets/img/movement/movement_video_screenshot4.png" />
          <p>Lockere deinen Körper und spüre mehr und mehr Gelassenheit.</p>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <div className={"cardContainer"}>
          <h4>Preview: Balance und Fokus finden</h4>
          <img src="/assets/img/movement/movement_video_screenshot5.png" />
          <p>Bringe den Fokus ganz in den Moment.</p>
        </div>
      </Grid>
    </div>
  )
}
